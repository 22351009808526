// this file is autogenerated by "pnpm generate-signalr-codes"

const GeneratedSignalRCodes = {
  SignalRCodes: {
    Events: {
      Notification: "Notification",
      UpdateDocumentState: "UpdateDocumentState",
    },
    Methods: {
      JoinProject: "JoinProject",
      LeaveProject: "LeaveProject",
      JoinTenant: "JoinTenant",
      LeaveTenant: "LeaveTenant",
    },
  },
}

export const SignalRCodes = GeneratedSignalRCodes.SignalRCodes;