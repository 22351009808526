import { PayloadAction, Middleware, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import * as signalr from '@microsoft/signalr';
import toast from 'react-hot-toast';
import { apiBase, ApiTagTypes, SignalRCodes } from '@client/shared/api';
import i18next from 'i18next';
import { Action } from 'redux-saga';
import { LoginActionPayload } from './uiSlice';

type SetProjectPayload = { projectId: string | undefined; variantId: string | undefined };

type SignalRMiddleWareActionPayload = {
  projectId?: string;
  tenantId?: string;
}

export const signalRMiddleware: Middleware = (store) => {
  let connection: signalR.HubConnection | null = null;
  let joinedProject = '';
  let joinedTenant = '';
  let registeredListeners = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = store.dispatch as ThunkDispatch<any, any, UnknownAction>;

  const registerListeners = () => {
    const handleNotification = (message: string) => {
      toast.success(i18next.t(message));
    };
    connection?.on(SignalRCodes.Events.Notification, handleNotification);

    const handleDocumentChanges = async (docId: string, _state: string) => {
      try {
        dispatch(
          apiBase.util.invalidateTags([
            // @ts-expect-error invalidate get ai eval documents
            { type: ApiTagTypes.InvoiceDocuments, id: joinedProject },
            // @ts-expect-error invalidate get ai document result
            { type: ApiTagTypes.InvoiceDocumentResult, id: docId, project: joinedProject },
            // @ts-expect-error invalidate get tasks list
            { type: ApiTagTypes.Tasks },
          ]),
        );
      } catch (err) {
        console.error(err);
      }
    };
    connection?.on(SignalRCodes.Events.UpdateDocumentState, handleDocumentChanges);
  };

  const joinProject = async (projectId: string) => {
    if (joinedProject === projectId || connection?.state === signalr.HubConnectionState.Connecting) return;

    try {
      await connection?.invoke(SignalRCodes.Methods.JoinProject, projectId);
    } catch (err) {
      console.log(err);
    }
    joinedProject = projectId;
  };

  const joinTenant = async (tenantId: string) => {
    if (joinedTenant === tenantId || connection?.state === signalr.HubConnectionState.Connecting) return;

    try {
      await connection?.invoke(SignalRCodes.Methods.JoinTenant, tenantId);
    } catch (err) {
      console.log(err);
    }
    joinedTenant = tenantId;
  };

  return (next) => async (action: unknown) => {
    const parsedAction = action as Action;
    switch (parsedAction.type) {
      case 'signalr/connect':
      {
        if (connection === null || connection?.state === signalr.HubConnectionState.Disconnected) {
          if (!connection) {
            connection = new signalr.HubConnectionBuilder().withUrl('/api/signalr').withAutomaticReconnect().build();

            await connection.start();
            console.log('connected to SignalR');
          } else if (connection.state === signalr.HubConnectionState.Disconnected) {
            await connection.start();
            console.log('connected to SignalR');
          }
        }

        const signalRConnectAction = parsedAction as PayloadAction<SignalRMiddleWareActionPayload>;
        const projectId = signalRConnectAction.payload?.projectId;
        const tenantId = signalRConnectAction.payload?.tenantId;

        if (tenantId && joinedTenant !== tenantId) {
          await joinTenant(tenantId);
        }

        if (projectId && joinedProject !== projectId) {
          await joinProject(projectId);
        }

        if (!registeredListeners) {
          registerListeners();
          registeredListeners = true;
        }
        break;
      }
      case 'signalr/disconnect': {
        if (connection !== null) {
          await connection.stop();
          connection = null;
          console.log('disconnected from SignalR');
        }
        break;
      }
      case 'project/setProjectId': {
        const setProjectAction = parsedAction as PayloadAction<SetProjectPayload | undefined>;
        const projectId = setProjectAction?.payload?.projectId;
        if (projectId) {
          dispatch({ type: 'signalr/connect', payload: { projectId: projectId, tenantId: joinedTenant } });
        }
        return next(action);
      }
      case 'ui/login': {
        const loginAction = parsedAction as PayloadAction<LoginActionPayload | undefined>
        const tenantId = loginAction?.payload?.tenant?.tenantId;
        if (tenantId) {
          dispatch({ type: 'signalr/connect', payload: { tenantId: tenantId }});
        }
        return next(action);
      }
      default:
        return next(action);
    }
  };
};
