import { configureStore } from '@reduxjs/toolkit';
import { rootSaga } from './rootSaga';
import { api } from '@client/shared/api';
import { uiSlice, signalRMiddleware } from '@client/shared/store';
import createSagaMiddleware from 'redux-saga';
import { projectSlice, idListSlice, unitSystemSlice, filterSlice, datevAuthSlice } from '@client/project/store';
import { portfolioSlice } from '@client/portfolio/store';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    ui: uiSlice.reducer,
    api: api.reducer,
    project: projectSlice.reducer,
    idList: idListSlice.reducer,
    unitSystem: unitSystemSlice.reducer,
    filters: filterSlice.reducer,
    portfolio: portfolioSlice.reducer,
    datev: datevAuthSlice.reducer
  },
  middleware: (defaultMiddleware) => defaultMiddleware().concat(sagaMiddleware, api.middleware, signalRMiddleware),
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
